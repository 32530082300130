import React from 'react';
import Newsletter from '../Components/Newsletter';

const Mint = () => {
  const footerMenu = [
    {
      text: 'Privacy Policy',
      link: '/'
    },
    {
      text: 'Terms & Conditions',
      link: '/'
    },
    {
      text: 'Liscense',
      link: '/'
    },
    {
      text: 'Purchase Agreement',
      link: '/'
    }
  ];
  return (
    <>
    <section id='margin_top'>
      <div className="mint">
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 col-xl-8">
              <div className="mint-wrapper">
                <div className="img">
                  <img src="/images/mint.png" alt="mint" className='img-fluid' />
                </div>
                <div className="calculator">
                  <p>Price</p>
                  <h4>TBA</h4>
                  <div className="mb-3">
                    <label htmlFor="quantity">Remaining Quantity</label>
                    <input type="text" name="Quantity" id="quantity" className='form-control' />
                  </div>
                  <div className="mb-3 d-flex plus-minus">
                    <label htmlFor="mint">Mint Quantity</label>
                    <div className="d-flex">
                      <button className='btn btn-minus'>-</button>
                      <input type="text" className='form-control' id='mintQuantity' />
                      <button className='btn btn-plus'>+</button>
                    </div>
                    <button className='btn btn-red'>Mint now</button>
                  </div>
                  <button className='btn btn-gray'>Connect Wallet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className='footer-mint'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <h2>enter your email for a free in-game item</h2>

            <div className="news-letter">
              <p>At game launch, the free in-game item will be airdropped  to your wallet. By submitting your email you agree to our Terms of Use and Sale and Privacy Policy. You will receive email communications from us for marketing, informational, and promotional purposes and can opt-out at any time.</p>
              <Newsletter />
              <div className="img">
                <img src="/images/footer-mint.png" alt="warehouse" className='img-fluid' />
              </div>
            </div>

            <div className="footer-menu d-flex">
              <ul className='footer-menu-main'>
                {footerMenu.map(({link, text}) => {
                  return (
                    <li className='link-item' key={text}>
                      <a href={link}>{text}</a>
                    </li>
                  )
                })}
              </ul>
              <p>&copy; 2022 LITD,  All rights reserved.</p>
            </div>

          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Mint
