import React, { useState } from 'react'

const Newsletter = () => {

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null)

  const FORM_URL = `https://app.convertkit.com/forms/4908071/subscriptions`;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const data = new FormData(event.target  , HTMLFormElement)

    try {
      const response = await fetch(FORM_URL, {
        method: "post",
        body: data,
        headers: {
          accept: "application/json",
        },
      })

      setEmail("")
      const json = await response.json()

      if (json.status === "success") {
        setStatus("SUCCESS")
        return
      }
    } catch (err) {
      setStatus("ERROR")
      console.log(err)
    }
  }

  const handleEmailChange = (event) => {
    const { value } = event.target
    setEmail(value)
  }

  return (
    <>
      {status === "SUCCESS" && (
        <>
          <p className='text-center text-light'>Please check your inbox to confirm the subscription!</p>
        </>
      )}
      {status === "ERROR" && (
        <>
          <p>Oops, something went wrong...</p>
          <p>
            Please,{" "}
            <button onClick={() => setStatus(null)}>try again.</button>
          </p>
        </>
      )}
      {status === null && (
        <form className='newsletter' onSubmit={handleSubmit}>
          <div className="d-flex">
            <input
              className='form-control'
              aria-label="Your email address"
              name="email_address"
              placeholder="Your email address"
              required
              type="email"
              onChange={handleEmailChange}
              value={email}
            />
            <button className="btn btn-red">SUBSCRIBE</button>
          </div>
        </form>
      )}
    </>
  )
}

export default Newsletter
