import React from 'react';
import FooterSocialIcons from './FooterSocialIcons';
import Newsletter from './Newsletter';

const Footer = () => {

  const footerMenu = [
    {
      text: 'Privacy Policy',
      link: '/'
    },
    {
      text: 'Terms & Conditions',
      link: '/'
    },
    {
      text: 'Liscense',
      link: '/'
    },
    {
      text: 'Purchase Agreement',
      link: '/'
    }
  ];

  return (
    <footer id='newsletter' className='bg-black'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-8">
            <div className="d-flex align-items-center">
              <div className="logo">
                <img src="/images/logo.png" className='img-fluid' alt="Light in the Dark" />
              </div>
              <div className="news-letter">
                <h4>Subscribe to our newsletter to stay up to date with all the latest info about our upcoming mint, game developments and More.</h4>
                <Newsletter />
              </div>
            </div>
            <div className="footer-menu d-flex">
              <ul className='footer-menu-main'>
                {footerMenu.map(({link, text}) => {
                  return (
                    <li className='link-item' key={text}>
                      <a href={link}>{text}</a>
                    </li>
                  )
                })}
              </ul>
              <p>&copy; 2022 LITD,  All rights reserved.</p>
            </div>

            <FooterSocialIcons />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
