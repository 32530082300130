import React from 'react';
import Slider from "react-slick";

const ImageCarousel = () => {
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const images = [
    {
      id: 1,
      alt: 1,
      img: '1.png'
    },
    {
      id: 2,
      alt: 2,
      img: '2.png'
    },
    {
      id: 3,
      alt: 3,
      img: '3.png'
    },
    {
      id: 4,
      alt: 4,
      img: '4.png'
    },
    {
      id: 5,
      alt: 5,
      img: '5.png'
    },
    {
      id: 6,
      alt: 6,
      img: '6.png'
    },
    {
      id: 7,
      alt: 7,
      img: '7.png'
    },
    {
      id: 8,
      alt: 8,
      img: '8.png'
    },
    {
      id: 9,
      alt: 9,
      img: '9.png'
    },
    {
      id: 10,
      alt: 10,
      img: '10.png'
    },
    {
      id: 11,
      alt: 11,
      img: '11.png'
    },
  ]

  return (
    <section id='image-carousel'>
      <div className="image-carousel bg-black overflow-hidden">
        <Slider {...settings}>
          {images.map(({id, alt, img}) => {
            return (
              <div className='image' key={id}>
                <img src={`/images/slider/${img}`} alt={alt} className='img-fluid' />
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default ImageCarousel
