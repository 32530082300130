import React from 'react'
import Buildings from '../Components/Buildings'
import Footer from '../Components/Footer'
import Founders from '../Components/Founders'
import Hero from '../Components/Hero'
import ImageCarousel from '../Components/ImageCarousel'
import Relm from '../Components/Relm'
import Tabs from '../Components/Tabs'
import Timeline from '../Components/Timeline'
import Whitepaper from '../Components/Whitepaper'

const Home = () => {
  return (
    <>
      <Hero />
      <Relm />
      <Tabs />
      <Buildings />
      <Whitepaper />
      <ImageCarousel />
      <Timeline />
      <Founders />
      <Footer />
    </>
  )
}

export default Home
