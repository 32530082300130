import React from 'react'
import Slider from 'react-slick';

const Founders = () => {
  let settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ],
  };

  const founders = [
    {
      name: 'Creighton Lee',
      desc: '“I believe creativity, truth, and vision are the building blocks of success. Light in the Dark utilizes these blocks to provide immersive storytelling and rewarding experiences that create an ecosystem filled with adventure and oppurtunity.”',
      link: 'https://twitter.com/',
      img: '1.png'
    },
    {
      name: 'Anthony Bower',
      desc: '“From drawing board to blockchain I have always believed in making Light in the dark more than just a game! We aim to give as much value to our players as possible through a strong community with tenacious leadership.”',
      link: 'https://twitter.com/',
      img: '2.png'
    },
  ]

  return (
    <section id='team'>
      <div className="founders bg-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className='sect-heading'>Founders</h2>
            </div>

            <div className="col-12">
              <Slider {...settings}>
                {founders.map(({name, desc, link, img}) => {
                  return (
                    <div className="founder-item" key={name}>
                      <div className="img">
                        <img src={`/images/founder/${img}`} alt={name} className='img-fluid' />
                      </div>
                      <div className="content">
                        <h3>{name}</h3>
                        <p>{desc}</p>
                        <a href={link} className='btn btn-blue'>Twitter</a>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Founders
