import React from 'react'

const Whitepaper = () => {
  return (
    <section id='whitepaper'>
      <div className="whitepaper bg-black">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-start">
              <h2>Whitepaper</h2>
              <p>Read the Light in the Dark Whitepaper to learn all the details & breakdowns about the game itself. It covers all in-game mechanisms including economic structure, upgrade systems, effects, unlocks and more.</p>
              <p>Click the link below to get caught up to date with the latest game information and updates!</p>
              <a href="https://light-in-the-dark-1.gitbook.io/untitled/" className='btn btn-red'>Learn more</a>
            </div>
            <div className="col-lg-6 text-center">
              <img src="images/warehouse.png" className='img-fluid' alt="warehouse" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Whitepaper
