import React from 'react'

const Hero = () => {
  return (
    <section id="margin_top">
      <div className="hero">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col-12 col-xl-8">
              <div className="content">
                <h2>Welcome to </h2>
                <h1>Paradise</h1>
                <p>Immersive Storytelling, Innovative Utility, Community Driven.</p>
                <a href="https://light-in-the-dark-1.gitbook.io/untitled/" className='btn btn-red'>Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
