import React from 'react';

const Timeline = () => {

  return (
    <section id='roadmap'>
      <div className="timeline bg-black">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="sect-heading">Visionary Timeline</h2>
              <p className='caption'>Timeline events are subject to changes and surprise announcements that may affect official release dates.</p>
            </div>

            <div className="col-12">
              <div className="timeline-main">
                <div className="timeline-top">
                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2022 - Quarter 4</h4>
                      <ul>
                        <li>Website Reveal</li>
                        <li>Discord Launch</li>
                        <li>Tokenomics Breakdown</li>
                        <li>Genesis Mint</li>
                      </ul>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2023 - quarter 2</h4>
                      <ul>
                        <li>Full Game Launch</li>
                        <li>Marketplace Launch</li>
                        <li>Merch Season 1 Drop</li>
                        <li>Community Event 1</li>
                        <li>Battle Pass Launch - Season 1</li>
                      </ul>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2023 - quarter 4</h4>
                      <ul>
                        <li>IRL Public Lounge Opening</li>
                        <li>New Physical Holder Utilities</li>
                        <li>Merch Season 3 Drop</li>
                        <li>Light in the Dark Phase 2</li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="timeline-middle">
                  <svg width="100%" height="61" viewBox="0 0 1594 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.48242 56.9999C57.13 53.3949 73.8815 4.72846 132.512 4.72852C191.142 4.72857 198.321 49.79 260.541 56.9999" stroke="url(#paint0_linear_70_840)" strokeWidth="8" strokeLinecap="round" />
                    <path d="M272.486 56.9999C325.134 53.3949 341.885 4.72846 400.516 4.72852C459.146 4.72857 466.325 49.79 528.545 56.9999" stroke="url(#paint1_linear_70_840)" strokeWidth="8" strokeLinecap="round" />
                    <path d="M540.488 56.9999C593.136 53.3949 609.887 4.72846 668.518 4.72852C727.148 4.72857 734.327 49.79 796.547 56.9999" stroke="url(#paint2_linear_70_840)" strokeWidth="8" strokeLinecap="round" />
                    <path d="M809.236 56.9999C861.884 53.3949 878.635 4.72846 937.266 4.72852C995.896 4.72857 1003.08 49.79 1065.3 56.9999" stroke="url(#paint3_linear_70_840)" strokeWidth="8" strokeLinecap="round" />
                    <path d="M1077.24 56.9999C1129.89 53.3949 1146.64 4.72846 1205.27 4.72852C1263.9 4.72857 1271.08 49.79 1333.3 56.9999" stroke="#4F4545" strokeWidth="8" strokeLinecap="round" />
                    <path d="M1333.3 56.9999C1385.95 53.3949 1402.7 4.72846 1461.33 4.72852C1519.96 4.72857 1527.14 49.79 1589.36 56.9999" stroke="#4F4545" strokeWidth="8" strokeLinecap="round" />
                    <defs>
                      <linearGradient id="paint0_linear_70_840" x1="132.512" y1="4.72852" x2="132.512" y2="56.9999" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#58E825" />
                        <stop offset="1" stopColor="#0F6712" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_70_840" x1="400.516" y1="4.72852" x2="400.516" y2="56.9999" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4F4545" />
                        <stop offset="1" stopColor="#4F4545" />
                      </linearGradient>
                      <linearGradient id="paint2_linear_70_840" x1="668.518" y1="4.72852" x2="668.518" y2="56.9999" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4F4545" />
                        <stop offset="1" stopColor="#4F4545" />
                      </linearGradient>
                      <linearGradient id="paint3_linear_70_840" x1="937.266" y1="4.72852" x2="937.266" y2="56.9999" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#4F4545" />
                        <stop offset="1" stopColor="#4F4545" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

                <div className="timeline-bottom">
                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2023 - quarter 1</h4>
                      <ul>
                        <li>Soft Game Launch</li>
                        <li>Team Expansion</li>
                        <li>$60,000 community donation</li>
                        <li>Artwork Update</li>
                      </ul>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2023 - quarter 3</h4>
                      <ul>
                        <li>Merch Season 2 Drop</li>
                        <li>Community Event 2</li>
                        <li>Battle Pass - Season 2</li>
                        <li>$90,000 founders donation</li>
                        <li>Metaverse land acquisitions</li>
                      </ul>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner">
                      <h4>2024 - quarter 1</h4>
                      <ul>
                        <li>Upcoming</li>
                        <li>Upcoming</li>
                        <li>Upcoming</li>
                        <li>Upcoming</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Timeline
