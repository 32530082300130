import React from 'react';
import Newsletter from './Newsletter';


const Buildings = () => {

  const buildings = [
    {
      id: '1',
      link: '/images/buildings/1.png',
      title: 'home town',
      content: 'The Hometown is the heart of your parcel. Upgrade it to unlock new upgrades for other buildings.'
    },
    {
      id: '2',
      link: '/images/buildings/2.png',
      title: 'lumber mill',
      content: 'Lumber Mills are responsible for wood production on your parcel. Wood is required for the majority of basic tasks around your parcel.'
    },
    {
      id: '3',
      link: '/images/buildings/3.png',
      title: 'sword smith',
      content: "The Swordsmith is the first weapon engineering building that can be built. Build some of the realm's mightiest swords here."
    },
    {
      id: '4',
      link: '/images/buildings/4.png',
      title: 'work shop',
      content: 'Need some extra hands? Build the workshop and hire some experts to give you a hand around your parcel.'
    },
    {
      id: '5',
      link: '/images/buildings/5.png',
      title: 'loading docks',
      content: 'The Loading Docks are your means for exporting goods. Start exporting to start making $LIGHT.'
    },
    {
      id: '6',
      link: '/images/buildings/6.png',
      title: 'library',
      content: 'The Library controls the amount of parcel knowledge you have. Buildings can’t be built or upgraded without first learning it here.'
    },
    {
      id: '7',
      link: '/images/buildings/7.png',
      title: 'rock quarry',
      content: 'Rock Quarries are responsible for collecting rock on your parcel. Rock is a basic component of most crafts.'
    },
    {
      id: '8',
      link: '/images/buildings/8.png',
      title: 'Iron mine',
      content: 'Iron Mines are responsible for collecting Iron on your parcel. Iron is the first valuable resource found.'
    },
  ];

  return (
    <section id='buildings'>
      <div className="buildings bg-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className='sect-heading'>in-game Buildings</h2>
            </div>

            <div className="col-12">
              <div className="building-blocks">
                {buildings.map(({ id, link, title, content }) => {
                  return (
                    <div key={link}>
                      <div id={id} className="building-block text-center">
                        <img src={link} alt={title} className='img-fluid' />
                        <h4>{title}</h4>

                        <div className="content">
                          <p>{ content }</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="col-12 col-xl-6">
              <div className="free-item text-center">
                <h3>enter your email for a free in-game item</h3>
                <p>At game launch, the free in-game item will be airdropped  to your wallet. By submitting your email you agree to our Terms of Use and Sale and Privacy Policy. You will receive email communications from us for marketing, informational, and promotional purposes and can opt-out at any time.</p>
                <Newsletter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Buildings
