import React from 'react'

const Relm = () => {
  return (
    <section id='relm'>
      <div className="relm bg-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-8 text-center">
              <h2 className='sect-heading'>The Relm of Light</h2>
              <p>Light in the Dark is an ambitious, unique P2E universe that is currently under development. Players will mint land that will be theirs to explore, build, trade, mine, craft and so much more! Explore the land, earn $LIGHT, and uncover the mysteries hidden along the way. Stay weary, the darkness is spreading across the realm rapidly...</p>
              <p>The future of Light in the Dark holds unique oppurtunities in the form of utilities offered, which include charitable donations, artwork upgrades, ecosystem incentives and more. The future is bright, but it all starts with you and the community we build together!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Relm
