import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Tabs() {
  return (
    <section id='tabs'>
      <div className="tabs bg-black">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Carousel>
                <div className="carousel-item">
                  <img src="/images/57.png" alt="Slide 1" />
                  <div className="content">
                    <h2>Small Parcel #57</h2>
                    <p className='big-p'>Grass Variation</p>
                    <p>Players with Small Parcel #57 will be happy to find a Large Gold Mountain which gives them the ability to sell gold before it is widely available. The combination of pine trees and waterfall #7 provides the player a strategic advantage when harvesting resources, which is a crucial part of gameplay.</p>
                    <p><strong>Attributes:</strong></p>
                    <ul>
                      <li>Grass</li>
                      <li>Dirt</li>
                      <li>Pine Trees</li>
                      <li>Waterfall #7</li>
                      <li>Large Gold Mountain</li>
                      <li>??????</li>
                    </ul>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/images/104.png" alt="Slide 1" />
                  <div className="content">
                    <h2>Small Parcel #104</h2>
                    <p className='big-p'>Sand Variation</p>
                    <p>All parcels containing full sand landscapes are some of the rarest to find. Sand parcels have the capability to produce glass, which can provide highly valuable benefits for weapon manufacturing. The sand can easily bury things, a shovel may be useful…</p>
                    <p><strong>Attributes:</strong></p>
                    <ul>
                      <li>Sand</li>
                      <li>Palm Tree #1</li>
                      <li>River #4</li>
                      <li>Gold Mountain</li>
                    </ul>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="/images/98.png" alt="Slide 1" />
                  <div className="content">
                    <h2>Small Parcel #98</h2>
                    <p className='big-p'>Hybrid Variation</p>
                    <p>Parcel #98 will give players early access to diamonds, which open doors to certain crafting and land mechanics earlier than standard, head over to our whitepaper to learn all the exact details regarding diamond usage. The sun can be quite powerful, if only you could harness it somehow…</p>
                    <p><strong>Attributes:</strong></p>
                    <ul>
                      <li>Grass Terrain</li>
                      <li>Dirt Foundation</li>
                      <li>Pine Trees </li>
                      <li>Waterfall #7</li>
                      <li>Large Gold Mountain</li>
                      <li>??????</li>
                    </ul>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default Tabs
